import CheckIcon from '@mui/icons-material/Check'
import React, { useEffect } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { ASSETS_URL } from '../config/config'
import { UTMData } from '../Constants'
import { push_pageview } from '../service/analytics'
import { initializeInvoicing, isInvoiceFlow } from '../utils/invoiceUtils'
import IDForm from './Forms/IDForm'
import InvoiceForm from './Forms/InvoiceForm'
import OtherForm from './Forms/OtherForm'
import Navigation from './misc/Navigation'

const VerifyAccount: React.FC = () => {
  // Setup for potential invoice flow
  initializeInvoicing()
  const is_invoice = isInvoiceFlow()
  const defaultTabIndex = is_invoice ? 2 : 0

  useEffect(() => {
    push_pageview({ page_id: 'express_pay_verify_account', page_name: 'Verify Account' })
    const params = new URLSearchParams(window.location.search)
    const UTM_Source__c = params.get('utm_source') || params.get('mdvip_source') || 'direct'
    const UTM_Medium__c = params.get('utm_medium') || params.get('mdvip_medium') || 'none'
    const UTM_Campaign__c = params.get('utm_campaign') || params.get('mdvip_campaign') || ''
    const Source_URL__c = location.href || 'https://www.mdvip.com/pay?undefined'

    const UTMValues: UTMData = {
      Source_URL__c: Source_URL__c,
      Web_MD_Domain__c: Source_URL__c,
      UTM_Campaign__c: UTM_Campaign__c,
      UTM_Medium__c: UTM_Medium__c,
      UTM_Source__c: UTM_Source__c,
      UTM_Term__c: params.get('utm_term') || params.get('mdvip_term'),
    }

    window.sessionStorage.removeItem('mdvip_ep_customer_data')
    const mdvip_ep_customer_data = JSON.stringify(UTMValues)
    window.sessionStorage.setItem('mdvip_ep_customer_data', mdvip_ep_customer_data)
  }, [])

  return (
    <div className="page-verify-account form-content">
      <Navigation activeIndex={0} />
      <div className="main-content">
        <h1>Verify Account</h1>
        <div className="content">
          <div className="options">
            <Tabs defaultIndex={defaultTabIndex}>
              <TabList>
                <Tab disabled={is_invoice}>
                  <CheckIcon />
                  MDVIP Id
                </Tab>
                <Tab disabled={is_invoice}>
                  <CheckIcon />
                  Invoice #
                </Tab>
                <Tab>
                  <CheckIcon />
                  Other
                </Tab>
              </TabList>
              <TabPanel disabled={is_invoice}>
                <IDForm />
              </TabPanel>
              <TabPanel disabled={is_invoice}>
                <InvoiceForm />
              </TabPanel>
              <TabPanel>
                <OtherForm />
              </TabPanel>
            </Tabs>
          </div>
          <div className="side-image">
            <img src={`${ASSETS_URL}/assets/images/infographic-image.svg`} alt="Infographic" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyAccount
