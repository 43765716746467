import { ApolloProvider } from '@apollo/client'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useEffect } from 'react'
import React from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'

import { useApolloClient } from '../hooks/useApolloClient'
import { push_pageview } from '../service/analytics'
import { formatDateString, parseServerDateToLocalDateString } from '../utils/dateUtils'
import { generateRouteWithInvoice, initializeInvoicing, isInvoiceFlow } from '../utils/invoiceUtils'
import ReviewForm from './Forms/ReviewForm'
import Navigation from './misc/Navigation'
import { PaymentInfoPropState } from './PaymentInfo'

export interface ReviewPropState {
  mdvipId: string
  dob: string
  balance: string
  amount: string
  paymentMethod: string
  token: string
  scheduled: Date
  estimatedPaidThrough: string
}

const Review: React.FC = () => {
  useEffect(() => {
    push_pageview({ page_id: 'express_pay_review', page_name: 'Review Transaction Details' })
  }, [])

  initializeInvoicing()
  const isInvoice = isInvoiceFlow()

  const location = useLocation()
  if (window.sessionStorage.getItem('session') === null) {
    const homeRoute = generateRouteWithInvoice('/')
    return <Navigate replace to={homeRoute} />
  }
  const { token, amount, paymentMethod, mdvipId, dob, balance, scheduled, estimatedPaidThrough } =
    location.state as ReviewPropState

  const client = useApolloClient(token)

  // Scheduled payment date is today + 1.
  const scheduledDateFormatted = formatDateString(scheduled)

  const paymentInfoState: PaymentInfoPropState = {
    mdvipId,
    dob,
    balance,
    token,
    estimatedPaidThrough,
  }

  const estimatedPaidThroughDateFormatted = parseServerDateToLocalDateString(estimatedPaidThrough)
  const amountDueLabel = parseInt(balance) <= 0 ? 'Estimated Paid Through Date' : 'Amount Due'
  const amountDueValue =
    parseInt(balance) <= 0
      ? estimatedPaidThroughDateFormatted
      : '$' +
        parseFloat(balance)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const paymentRoute = generateRouteWithInvoice('/payment')
  return client ? (
    <ApolloProvider client={client}>
      <div className="page-review form-content">
        {window.sessionStorage.getItem('session') === '0' && <Navigate replace to="/" />}
        <Navigation activeIndex={2} />
        <div className="main-content">
          <div className="back-link">
            <KeyboardBackspaceIcon />
            <Link to={paymentRoute} state={paymentInfoState} className="log-in">
              Add Payment info
            </Link>
          </div>

          <h1>Review</h1>

          <div className="content payment-info">
            <div className="container-fluid g-0">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="member-info">
                    <div className="table-info">Member Info</div>
                    <div className="table-row">
                      <div className="table-column">
                        <div className="label">Member ID</div>
                        <div className="info" data-private>
                          {mdvipId}
                        </div>
                      </div>
                      <div className="table-column">
                        <div className="label">Date of Birth</div>
                        <div className="info" data-private>
                          {dob}
                        </div>
                      </div>
                    </div>
                    <div className="table-info">Payment Info</div>
                    {(isInvoice && (
                      <>
                        <div className="table-row">
                          <div className="table-column">
                            <div className="label">Amount Being Paid</div>
                            <div className="info">
                              $
                              {parseFloat(amount)
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                          </div>
                          <div className="table-column">
                            <div className="label">Scheduled Payment Date</div>
                            <div className="info">{scheduledDateFormatted}</div>
                          </div>
                        </div>
                        <div className="table-row">
                          <div className="table-column">
                            <div className="label">Payment Method</div>
                            <div className="info" data-private>
                              {paymentMethod}
                            </div>
                          </div>
                        </div>
                      </>
                    )) || (
                      <>
                        <div className="table-row">
                          <div className="table-column">
                            <div className="label">{amountDueLabel}</div>
                            <div className="info">{amountDueValue}</div>
                          </div>
                          <div className="table-column">
                            <div className="label">Scheduled Payment Date</div>
                            <div className="info">{scheduledDateFormatted}</div>
                          </div>
                        </div>
                        <div className="table-row">
                          <div className="table-column">
                            <div className="label">Amount Being Paid</div>
                            <div className="info">
                              $
                              {parseFloat(amount)
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                          </div>
                          <div className="table-column">
                            <div className="label">Payment Method</div>
                            <div className="info" data-private>
                              {paymentMethod}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="disclamer">
                    Scheduled payments will be processed and reflected in your account in 1-2 days.
                  </div>
                </div>
                <div className="col-12 pay-button-column col-md-6 text-center d-flex justify-content-center flex-wrap">
                  <div className="total-and-payment">
                    <ReviewForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApolloProvider>
  ) : null
}

export default Review
