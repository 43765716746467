export const initializeInvoicing = (): void => {
  // Remove the invoice ID from the Session Storage
  removeInvoiceId()

  // Get the invoice ID from the URL
  const params = new URLSearchParams(window.location.search)
  const invoiceId = params.get('invoice_id')
  if (invoiceId) {
    // Set the invoice ID in the Session Storage
    setInvoiceId(invoiceId)
  }
}

export const getInvoiceId = (): string | null => {
  // Get the invoice ID from the Session Storage
  return window.sessionStorage.getItem('invoice_id')
}

export const setInvoiceId = (invoiceId: string): void => {
  // Set the invoice ID in the Session Storage
  window.sessionStorage.setItem('invoice_id', invoiceId)
}

export const removeInvoiceId = (): void => {
  window.sessionStorage.removeItem('invoice_id')
}

export const isInvoiceFlow = (): boolean => {
  return getInvoiceId() !== null
}

export const generateInvoiceURLToken = (): string => {
  const invoiceId = getInvoiceId()
  return invoiceId ? `invoice_id=${invoiceId}` : ''
}

export const generateRouteWithInvoice = (route: string, token = ''): string => {
  const invoiceToken = generateInvoiceURLToken()
  if (token !== '') {
    return invoiceToken ? `${route}?${invoiceToken}&${token}` : `${route}?${token}`
  }
  return invoiceToken ? `${route}?${invoiceToken}` : route
}
