import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

import React, { useState } from 'react'
import Modal from 'react-modal'
import { Document, Page, pdfjs } from 'react-pdf'
import { useLocation, useNavigate } from 'react-router-dom'

import { generateRouteWithInvoice } from '../../utils/invoiceUtils'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

interface InvoiceDataInterface {
  id: string
  title: string
  fileURL: string
  balance: number
}

interface InvoiceModalProps {
  isOpen?: boolean
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({ isOpen = false }) => {
  const navigate = useNavigate()
  const location = useLocation()

  let subtitle: HTMLHeadingElement | null
  const [modalIsOpen, setIsOpen] = React.useState(isOpen)
  const [numPages, setNumPages] = useState(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    if (subtitle) {
      subtitle.style.color = '#f00'
    }
  }

  function closeModal() {
    setIsOpen(false)
  }

  function goToPay() {
    const paymentRoute = generateRouteWithInvoice('/payment')
    closeModal()
    navigate(paymentRoute, { state: location.state })
  }

  const url =
    'https://corsproxy.io/?' +
    encodeURIComponent('https://researchtorevenue.wordpress.com/wp-content/uploads/2015/04/1r41ai10801601_fong.pdf')

  // Will be converted to graphql query when it's available.
  const getInvoiceData = (): InvoiceDataInterface => {
    return {
      id: '1',
      title: 'Invoice',
      fileURL: url,
      balance: 543.21,
    }
  }
  const invoiceData = getInvoiceData()

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

  return (
    <div className="invoice-modal">
      <a onClick={openModal} className="view-invoice">
        View Invoice
      </a>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Invoice Modal"
        ariaHideApp={false}
      >
        {/* <div className="modal" tabIndex={-1}> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal__header d-flex justify-content-between align-items-center">
              <h2 className="modal__title">View Invoice</h2>
              <button
                className="modal__button btn-close top-right"
                onClick={closeModal}
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <Document file={invoiceData.fileURL} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth / 2} />
                ))}
              </Document>{' '}
            </div>
            <div className="modal-footer">
              <a href={invoiceData.fileURL} target="_blank" rel="noreferrer" className="pdf-download">
                Download PDF
              </a>
              <button onClick={goToPay} className="btn btn-primary">
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InvoiceModal
